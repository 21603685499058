/* 🟡 TODO: Move to design 🟡 */

/* 🟡 END TODO: Move to design 🟡 */

/* 🟡 TODO: Old design overrides, delete once it is not in use 🟡 */
.mobile-header > .nav .btn-more {
  background: transparent;
  border-color: #fff;
  border-color: var(--contrast-light);
  color: #fff;
  color: var(--contrast-light);
  height: 28px;
  margin-left: 8px;
  margin-right: 8px;
  min-width: inherit;
  padding: 0;
  width: 28px;
}

.form-default.form-card {
  .input-v1-wrapper {
    .hint-block {
      padding: 0;
      margin-top: 8px;
      font-size: 12px;
    }

    & + .input-v1-wrapper,
    & + div .input-v1-wrapper {
      margin-top: -15px;
      border-top: 1px solid var(--border);
    }
  }
}

/* 🟡 END TODO: Old design overrides, delete once it is not in use 🟡 */
